<template>
	<div
		v-if="title || text"
		:id="$vnode.key"
		class="c-block-list-rich-text-editor-custom"
	>
		<LongReadTarget
			:disabled="Boolean(!title || accordionLevel || !includeInToc)"
			:container-id="$vnode.key"
			:title="title"
		/>

		<BaseH2 v-if="title" class="mb-lg" v-text="title" />

		<div
			v-if="text"
			class="s-rich-text"
			:class="{
				's-rich-text--monotone': accordionLevel,
			}"
			v-html="text"
		></div>
	</div>
</template>

<script>
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';

export default {
	name: 'BlockListRichTextEditorCustom',
	components: { LongReadTarget },

	inject: {
		accordionLevel: {
			default: 0,
		},
	},

	inheritAttrs: false,

	props: {
		title: String,
		includeInToc: Boolean,
		text: String,
	},
};
</script>
<style lang="postcss">
.c-block-list-rich-text-editor-custom img {
	width: 100% !important;
	height: auto !important;
}
</style>
